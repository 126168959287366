
$(function() {

	// prefix for the questions slider
		slider = $('.question-slider').bxSlider({
			mode: 'horizontal',
			touchEnabled: false,
			useCSS: false,
			infiniteLoop: false,
			hideControlOnEnd: true,
			controls: true,
			pager: false,			
			easing: 'easeInBack',
			speed: 500,
			onSlideBefore: function($slideElement, oldIndex, newIndex){
				$('.question-slider article').removeAttr('class');
				// set active class to current question
         			$slideElement.addClass('active');
         		// set the question page number
         			var slideIndex = $slideElement.attr('data-slide');
         			$('.pager span').html(slideIndex);
         		// show back navigation if not first question 
         			console.log($slideElement);
	         		if($slideElement.attr('id') == 'qt1')
	         		{
	         			$('header p.pager-prev').removeClass('active');
	         		} else {
	         			$('header p.pager-prev').addClass('active');
	         		}
	        },
		});

	// select the current option/question
		$('.options .selector').on('click', function() {
			$(this).siblings().removeClass('selected');
			$(this).addClass('selected');
			// go to next slide
				if( $(this).find('.image').hasClass('opt1')) {
					setTimeout(function() {
						slider.goToSlide(3);
					}, 500);
				}
				setTimeout(function() {
					$('.bx-next').click();
				}, 500);
		});

	// go back to prev option/question
		$('header p.pager-prev').on('click', function() {
			if( $(this).hasClass('active') )
			{
				var pageNumber = $('.pager span').html();
				pageNumber--;
				$('.pager span').html(pageNumber);			
				$('.bx-prev').click();
			}
		});

	// submit the form
		$('article#qt8 .selector').on('click', function() {
			setTimeout(function() {
				$('#question-results').submit();
			}, 500);
		});
});